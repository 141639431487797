<template>
  <Content>
    <template #contentTitle>{{ $t('menu.account_announcement') }}</template>
    <template #contentBody="wrap">
      <a-list size="small" :data-source="tableData.tableList" :loading="tableData.loading">
        <template #renderItem="{ item }">
          <a-list-item style="cursor: pointer;" @click="handleShowDetail(item)">
            <a-row :gutter="16" style="width: 100%;" type="flex" align="middle">
              <a-col flex="0 0 60px" @click.stop><a-checkbox v-model:checked="item.selected"></a-checkbox></a-col>
              <a-col flex="0 0 200px">
                <a-badge :dot="!item.isRead">
                  <div style="max-width: 180px;">
                    <a-typography-paragraph style="margin: 0;" :ellipsis="true" :content="item.title" />
                  </div>
                </a-badge>
              </a-col>
              <a-col flex="0 0 150px">
                <a-tag color="green" v-if="item.announceType === announceTypeEnum.updateLog">{{ $t($enumLangkey("announceType", item.announceType)) }}</a-tag>
                <a-tag color="orange" v-else-if="item.announceType === announceTypeEnum.systemAnnouncement">{{ $t($enumLangkey("announceType", item.announceType)) }}</a-tag>
              </a-col>
              <a-col flex="1">
                <div style="width: 200px;">
                  <a-typography-paragraph style="margin: 0;" :ellipsis="true" :content="item.digest" />
                </div>
              </a-col>
              <a-col flex="0 0 150px">
                <a-button type="link">{{ $t('logistics.look') }}</a-button>
              </a-col>
            </a-row>
          </a-list-item>
        </template>
        <template #header>
          <a-row :gutter="[16, 16]">
            <a-col>
              <a-button type="link" :loading="markLoading" :disabled="selectedCount === 0" @click="handleMarkAsRead()">{{ $t('account.mark_as_read') }}</a-button>
            </a-col>
            <a-col>
              <a-button type="link" :loading="markAllLoading" :disabled="tableData.pageData.totalCount === 0" @click="handleMarkAll()">{{ $t('account.all_read') }}</a-button>
            </a-col>
          </a-row>
        </template>
      </a-list>
      <AnnouncementContentModal v-model:visible="announcementState.visible" :id="announcementState.id" />
    </template>
    <template #contentFooter>
      <CPager class="text-center" :page-data="tableData.pageData" @handlePage="handlePage" />
    </template>
  </Content>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, toRefs } from 'vue';
import { Badge, Button, Checkbox, Col, List, Row, Table, Tag, Typography, message } from 'ant-design-vue';
import Content from '@/views/components/Content.vue';
import CPager from '@/views/components/CPager.vue';
import AnnouncementContentModal from './components/AnnouncementContentModal.vue';
import { useI18n } from "vue-i18n/index";
import { useStore } from "vuex";
import { announceType as announceTypeEnum } from '@/enum/enum.json';
import { getShopUserAnnounceListPaged, readShopUserAnnounce, readAllShopUserAnnounce } from '@/api/modules/account/index.js';

export default defineComponent({
  components: {
    ARow: Row,
    ACol: Col,
    AButton: Button,
    ATable: Table,
    ACheckbox: Checkbox,
    ATag: Tag,
    AList: List,
    AListItem: List.Item,
    ABadge: Badge,
    ATypographyParagraph: Typography.Paragraph,
    Content,
    CPager,
    AnnouncementContentModal,
  },
  setup () {
    const vueI18n = useI18n({ useScope: "global" });
    const { dispatch } = useStore();

    const state = reactive({
      tableData: {
        tableList: [],
        loading: false,
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
      announcementState: {
        visible: false,
        id: null,
      },
      markLoading: false,
      markAllLoading: false,
    })

    const selectedCount = computed(() => {
      return state.tableData.tableList.reduce((total, item) => {
        if (item.selected) {
          return total+=1;
        } else {
          return total;
        }
      }, 0);
    })

    const handleMarkAsRead = () => {
      let announceIds = state.tableData.tableList.filter(item => item.selected).map(item => item.announceId);
      if (!announceIds.length) {
        return;
      }
      state.markLoading = true;
      readShopUserAnnounce({ announceIds }).then(() => {
        message.success(vueI18n.t('common.succeed'));
        dispatch("user/setRefreshNews", true);
        getPageList();
      }).catch(() => {}).finally(() => {
        state.markLoading = false;
      });
    }

    const handleMarkAll = () => {
      state.markAllLoading = true;
      readAllShopUserAnnounce().then(() => {
        message.success(vueI18n.t('common.succeed'));
        dispatch("user/setRefreshNews", true);
        getPageList();
      }).catch(() => {}).finally(() => {
        state.markAllLoading = false;
      });
    }

    const handleShowDetail = (record) => {
      state.announcementState.visible = true;
      state.announcementState.id = record.announceId;

      readShopUserAnnounce({ announceIds: [record.announceId] }).then(() => {
        record.isRead = true;
        dispatch("user/setRefreshNews", true);
      }).catch(() => {}).finally(() => {
        state.markLoading = false;
      });
    }

    const getPageList = () => {
      state.tableData.loading = true;
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.searchStateCache
      );
      getShopUserAnnounceListPaged(data)
        .then((res) => {
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch((error) => {})
        .finally(() => {
          state.tableData.loading = false;
        });
    };

    const handleInitPage = () => {
      state.tableData.pageData.skipCount = 0;
      state.tableData.pageData.currentIndex = 1;
      getPageList();
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    onMounted(handleInitPage);

    return {
      ...toRefs(state),
      announceTypeEnum,
      selectedCount,
      handleMarkAsRead,
      handleMarkAll,
      handleShowDetail,
      handlePage,

    }
  }
})
</script>

<style scoped>

</style>