<template>
  <a-modal
    width="700px"
    v-model:visible="visible"
    :title="info.title"
    :centered="true"
    :closable="false"
    :mask-closable="false"
  >
    <a-card :loading="loading">
      <span v-html="info.content"></span>
    </a-card>
    <template #footer>
      <a-button type="ghost" @click="close">{{ $t('common.close') }}</a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, reactive, nextTick, toRefs, watch } from 'vue';
import { Button, Card, Modal } from 'ant-design-vue';
import { setFormStateValue } from '@/utils/general.js';
import { getAnnounce } from '@/api/modules/account/index.js';

export default defineComponent({
  components: {
    AModal: Modal,
    ACard: Card,
    AButton: Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: String,
  },
  setup (props, { emit }) {
    const state = reactive({
      visible: false,
      loading: false,
      info: {
        id: null,
        title: null,
        content: '',
      }
    })

    const getDetail = () => {
      state.loading = true;
      getAnnounce({ id: state.info.id }).then(({ result }) => {
        if (result) {
          setFormStateValue(state.info, result);
        }
      }).catch(() => {}).finally(() => {
        state.loading = false;
      });
    }

    watch(() => [props.visible, props.id], ([visible, id]) => {
      if (typeof visible === 'boolean') {
        state.visible = visible;
        if (visible) {
          state.info = {
            id: null,
            title: null,
            content: '',
          }
          nextTick(() => {
            if (!!id) {
              state.info.id = id;
              getDetail();
            }
          })
        }
      }
    })

    const close = () => {
      emit('update:visible', false);
      state.visible = false;
    }

    return {
      ...toRefs(state),
      close,
    }
  }
})
</script>

<style scoped>

</style>